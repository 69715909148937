@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src: local('Pretendard'), local('Noto Sans KR'), local('Noto+Sans'), local('나눔바른고딕'), local('NanumBarunGothic'), local('나눔바른고딕OTF'),local('NanumBarunGothicOTF'), local('애플 SD 산돌고딕 Neo'), local('Apple SD Gothic Neo'); 
  src: local(☺),
      url("assets/fonts/Pretendard-Regular.woff") format("woff")
}
@layer base {
  :root {
    --primary-color: #4060D4; /*64, 96, 212*/
    --primary-300: #E1E8FF; 
    --primary-200: #EDF1FF; 
    --primary-100: #F6F8FF; 
    --primary-50: #FBFCFF; 
    --primary-color-darken: #354EA9; 

    --slate-300: #cbd5e1;
    --gray-10: #f8f8fb;
    --gray-10: #f8f8fb;
    --gray-150: #F0F1F4;
    --bg-img-card : linear-gradient(to top, #F1F1F8 0%, #ffffff 60%, #ffffff 100%);
    --bg-img-card-active : linear-gradient(to top, #ffffff 0%, #F1F1F8 60%, #F1F1F8 100%);
    /* --box-shadow-default: rgba(0, 0, 0, 0.2) 0px 15px 40px -10px;  */
    --box-shadow-default-hover: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    --box-shadow-small:  rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    --box-shadow-small-hover: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    --box-shadow-0: rgba(0, 0, 0, 0.06) 0px 0px 10px 1px inset;
    --box-shadow-card:  rgba(14, 23, 73, 0.45) 0px 15px 20px -20px;
    --box-shadow-card-hover:  rgba(127, 139, 182, 0.5) 0px 4px 8px -2px, rgba(64, 96, 212, 0.08) 0px 0px 0px 1px;
    --box-shadow-card-sm:  rgba(14, 23, 73, 0.45) 0px 10px 15px -15px;
    --box-shadow-card-lg:  rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    --box-shadow-form: rgba(86, 106, 214, 0.2) 0px 0px 20px;
    --padding-default: 8px 12px;
    --padding-sm: 5px 10px;
    --padding-default-x: 12px;
    --padding-default-y: 8px;
  }
  * {
    word-break: break-all;
  }
}
html, body {
  font-size: 14px !important;
  line-height: 20px;
}
body {
  margin: 0;
  font-family: Pretendard, '본고딕', 'Malgun Gothic', '맑은 고딕', Arial, helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrap {
  min-width: 1500px;
  max-width: 100%;
}
.login {
  width: 100%;
  background:
  linear-gradient(to top right, rgb(218, 255, 250) 0%, transparent 60%), linear-gradient(to top left, rgb(100, 160, 250) 0%, transparent 40%), linear-gradient(to bottom right, rgb(169, 178, 255) 0%, transparent 40%), radial-gradient(at center bottom, rgb(237, 239, 255) 20%, transparent 70%), radial-gradient(at center right, rgb(179, 199, 255) 20%, transparent 90%);
  background-size: cover;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.bg-animation::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: 
  url(../src/assets/images/o.png) no-repeat,
  url(../src/assets/images/o.png) no-repeat,
  url(../src/assets/images/o.png) no-repeat;
  background-size: 38%, 38%, 38%; 
  background-position:
  40% -30%,
  75% 80%,
  35% 160%;
  /* filter: blur(0px); */
  animation: zoomBackground 3s cubic-bezier(0.25, 0.1, 0.25, 1) infinite alternate;
  will-change: transform, filter; /* 최적화 */
  z-index: 0;
}
@keyframes zoomBackground {
  from {
    background-position:
    45% -80%,
    85% 130%,
    20% 190%;
    /* filter: blur(6px); */
  }
  to {
    background-position:
    35% -40%,
    65% 80%,
    30% 160%;
    /* filter: blur(0px); */
  }
}
.login .login-contain {
  box-shadow: var(--box-shadow-card-lg);
  transition: all 0.3s;
}
.login .login-contain:hover {
  box-shadow: var(--box-shadow-card-hover);
}
.login .login-contain:hover .blurred-bg {
  background: rgba(64, 96, 212, 0.2);
  backdrop-filter: blur(5px);
}
.blurred-bg {
  background: rgba(64, 96, 212, 0.2);
  backdrop-filter: blur(15px);
  transition: all 0.3s;
}

.menu a:hover, .menu a.active  {
  color: var(--primary-color);
  background-color: var(--primary-50);
}
.menu-dark a:hover, .menu-dark a.active  {
  @apply text-white bg-slate-800;
}
.height100vh-300px {
  max-height: calc(100vh - 300px);
}
/* .goal-all-member .member-h-auto,
.goal-team-member .member-h-auto,
.request-member .member-h-auto {
  height: calc(100vh - 275px);
} */
/* .member-h-auto {
  max-height: calc(100vh - 250px);
}
.goal-list-h-auto {
  max-height: calc(100vh - 270px);
}
.goal-all-list .goal-list-h-auto {
  max-height: calc(100vh - 297px);
} */
.request-progress-DT .DT-scroll {
  max-height: calc(100vh - 290px) !important;
}
.request-all-list .DT-scroll {
  max-height: calc(100vh - 290px) !important;
}
.scroll-slim::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scroll-slim::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb {
  background-color: var(--gray-150);
  border-radius: 100px;
}
.scroll-slim:hover::-webkit-scrollbar-thumb,
textarea:hover::-webkit-scrollbar-thumb {
  background-color: var(--slate-300);
}
table tr th {
  @apply text-slate-500 p-2 text-xs
}
table tr td {
  @apply p-2 text-center
}
.DT-scroll {
  max-height: calc(100vh - 250px);
  overflow-y: auto !important;  
}
.DT-scroll thead th {
  position: sticky;
  top: 0;
  background: var(--gray-10);
  z-index: 10;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1); /* 헤더 고정 시 약간의 그림자 추가 */
}
.member-card {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 50%;
  border: solid 1px white;
}
.member-card-selected {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%234060D4"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 50%;
  background-color: var(--primary-100);
  border: solid 1px var(--primary-color);
  scale: 1.02;
}
.member-card:not(.member-card-selected):hover {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23E5E7EB"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 50%;
  scale: 1.02;
}

.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.loading:before,
.loading:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.3s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(64, 96, 212, 0.75));
}
.loading:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem #4060D4;
  animation-name: pulsIn;
}
.loading:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #4060D4;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #4060D4;
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #4060D4;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #4060D4;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem #4060D4;
    opacity: 1;
  }
}



@layer utilities {
  .bg-gray-10 {
    background-color: var(--gray-10);
  }
  .bg-gray-150 {
    background-color: var(--gray-150);
  }
  .bg-primary-100 {
    background-color: var(--primary-100);
  }
  .bg-primary-200 {
    background-color: var(--primary-200);
  }
  .scal-102 {
    transform: scale(1.02);
  }
}

@layer components {
  .ellipsis {
		@apply overflow-hidden text-ellipsis whitespace-nowrap;
	}
  /*button*/
  button, a {
    transition: all .1s;
  }
  .btn {
    border-radius: 6px !important;
    padding: var(--padding-default);
    transition: all .1s;
    font-weight: bold;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4.5rem;
  }
  
  .btn-sm {
    border-radius: 4px !important;
    padding: var(--padding-sm);
    font-size: .9rem !important;
  }
  /* .btn:hover {
    box-shadow: var(--box-shadow-small);
  }
  .btn:focus {
    box-shadow: var(--box-shadow-0);
  } */
  .btn:disabled {
    @apply text-gray-400 bg-gray-300 cursor-default pointer-events-none;
  }
  
  .btn-primary {
    @apply bg-primary-300 text-primary;
  }
  .btn-outline-primary {
    border: solid 1px var(--primary-color);
    background-color: transparent !important;
    color: var(--primary-color);
  }
  .btn-outline-primary:hover {
    background-color: var(--primary-color) !important;
  }
  .btn-primary:hover {
    @apply bg-primary;
  }
  .btn-primary:focus, .btn-primary:active {
    @apply bg-primary;
  }
  .btn-secondary {
    @apply bg-slate-200 text-slate-500;
  }
  .btn-secondary:hover {
    @apply bg-slate-500;
  }
  .btn-secondary:focus, .btn-secondary:active {
    @apply bg-slate-600;
  }
  .btn-danger {
    @apply text-red-600 bg-red-50;
  }
  .btn-danger:hover {
    @apply bg-red-600;
  }
  .btn-danger:focus, .btn-danger:active {
    @apply bg-red-800;
  }
  .btn-primary:hover,.btn-primary:focus,.btn-secondary:hover,.btn-secondary:focus,.btn-danger:hover, .btn-danger:focus {
    color: white;
  }
  .btn-only-icon {
    padding: 8px !important;
    /* background: var(--primary-300); */
    /* color: var(--primary-color); */
    border-radius: 6px;
    min-width: auto !important;
  }
  .btn.w-full {
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }


  /*form*/
  .form-label {
    @apply text-xs text-slate-500;
    margin-bottom: 2px;
    display: inline-block;
  }
  .form-label.essential::after {
    content: '*';
    color: red;
    padding-left: 4px;
  }
  .form {
    @apply border rounded-md border-gray-200;
    padding: var(--padding-default);
    /* border-color: var(--gray-200); */
    font-size: 14px;
    line-height: 20px;
  }
  .form-sm {
    padding: var(--padding-sm);
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
  }
  .form::placeholder {
    @apply text-gray-400;
  }
  .form:focus {
    @apply ring-1;
  }
  .form:focus {
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: var(--box-shadow-form);
  }
  .form:disabled {
    opacity: .5;
    background-color: var(--gray-150);
    cursor: default;
    pointer-events: none;
  }
  textarea.form:read-only {
    opacity: .5;
    background-color: var(--gray-150);
  }

  .form-search {
    padding-right: 40px;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%238A8F96' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0' clip-rule='evenodd'/></svg>");        
    background-repeat: no-repeat;
    background-position: calc(100% - 10px);
    background-size: 1rem;      
  }
  .form-search:focus {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%234060D4' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0' clip-rule='evenodd'/></svg>");        
  }
  input[type='checkbox'] {
    @apply w-4 h-4 leading-4 border rounded-sm border-slate-400;
  }
  input[type='checkbox']:checked,
  input[type='checkbox']:focus {
    box-shadow: none !important;
  }
  input[type='checkbox']:checked {
    background-color: var(--primary-color) !important;
  }
  textarea {
    resize: none;
  }
  select {
    cursor: pointer;
    background-position: right 0.15rem center !important;
  }

  .box-shadow-card {
    box-shadow: var(--box-shadow-card);
  }
  .box-shadow-card-sm {
    box-shadow: var(--box-shadow-card-sm);
  }
  .box-shadow-card-sm:hover {
    box-shadow: var(--box-shadow-card-hover);
  }
  .box-shadow-card-hover:hover {
    box-shadow: var(--box-shadow-card-hover);
  }
  .box-shadow-default-hover {
    box-shadow: var(--box-shadow-default-hover);
  }
}


/*컴포넌트 스타일 수정*/
.react-tooltip {
  padding: 3px 8px !important;
  /* opacity: .8 !important; */
  font-size: 0.85rem !important;
  z-index: 1000;
}

.rc-tree-node-content-wrapper.rc-tree-node-selected {
  background-color: var(--primary-100) !important;
  color: var(--primary-color) !important;
  font-weight: bold;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
  opacity: 1 !important;
  border-radius: 2px !important;
}
.rc-tree-node-content-wrapper.rc-tree-node-selected .rc-tree-icon__customiz {
  color: var(--primary-color) !important;
}

.collapse-container {
  transition: all 0.3s ease;
}
.collapse-container.active {
  border-color: var(--primary-color);
  background-color: var(--primary-100);
  font-weight: normal;
}

/* 폭죽스타일 */
@keyframes firework {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}

.firework {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: firework 1s ease-out;
}

.firework:nth-child(1) {
  background-color: #ff4136;
  top: 30%;
  left: 20%;
}

.firework:nth-child(2) {
  background-color: #0074d9;
  top: 40%;
  left: 60%;
  animation-delay: 0.25s;
}

.firework:nth-child(3) {
  background-color: #b10dc9;
  top: 70%;
  left: 30%;
  animation-delay: 0.5s;
}

.cursor-ew-resize {
  cursor: url('assets/images/cursor.svg') 16 16, auto; /* 커스텀 커서 적용 */
}
.cursor-ns-resize {
  cursor: url('assets/images/cursorY.svg') 16 16, auto; /* 커스텀 커서 적용 */

}